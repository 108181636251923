import { IConfigData } from "@models/index";

export const environment: IConfigData = {
    // APIs
    apiEndPoint: "https://api.listedemariage.marcovasco.fr/api/0",
    apiToken:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE0NDc4NDE3MzIsImp0aSI6IlU2d2FEcWp4dFNWVktoU0syN1JUQ05tN2dcL1ZISGRHRW1wYm9uYVwvenJvRT0iLCJpc3MiOiJNYXJjb1Zhc2NvIiwiZGF0YSI6eyJhcHAiOiJDbGllbnQifX0.GAz-In9GIoik-qj5mJL2iH7tSFCJWjTQvoeGErUdvi1Ju4frax0Ii6MTrp_q_-XSnMxDiVCPr3ixick1CQyunQ",
    apiCountries: "https://common.marcovasco.fr/1/countries",
    apiImages: "https://client.marcovasco.fr/api/1/countriestemplates",
    apiPayments: "https://payments.marcovasco.fr",
    apiFolderPublic: "https://api.listedemariage.marcovasco.fr",
    pathAssets: "/assets/build/prod/js/vendor/lists-view/",
    apiPdfMarco: "https://pdfmarco.marcovasco.fr",
};
